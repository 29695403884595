
import footerMenu from '@/footerMenu.json'
import { EventBus } from '@/utils/event-bus.js';
import globalEventListeners from '@/mixins/globalEventListeners';

export default {
  mixins: [globalEventListeners],

  data() {
    return {
      footerMenuIndex: null,
      contactCard: {},
      contactButton: {},
      footerMenuGroup: {},
      socialLinks: [],
      subMenuGroup: [],
      show: false,
      isMobile: false,
      isModalOpen: false,

    }
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.breakpoints, {
        passive: true,
      })
    }
    EventBus.$off('open-modal', this.openModal);
    this.removeGlobalEventListeners();
    document.removeEventListener('keydown', this.handleKeyPress);


  },
  async mounted() {
    this.breakpoints()
    window.addEventListener('resize', this.breakpoints, {
      passive: true,
    })
    await this.getData()
    
    // Listen Global Events
    EventBus.$on('open-modal', this.openModal);
    this.addGlobalEventListeners();
    document.addEventListener('keydown', this.handleKeyPress);

  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
    layout() {
      return this.$route.matched.length > 0 ? this.$route.matched[0].components.default.options.layout : 'default';
    },
    hasLeadFormSingle() {
      return this.$route.matched.some(
        (route) => route.components.default.name === 'LeadFormSingle'
      );
    },
  },
  created() {
    EventBus.$on('open-modal', this.openModal);
    this.addGlobalEventListeners();


  },
  methods: {
    stripTrailingSlash(str) {
      return str.length > 1 && str.endsWith('/') ? str.slice(0, -1) : str
    },
    async getData() {
      try {
        const response = await footerMenu
        this.contactCard = await response.ContactCard
        this.contactButton = this.contactCard.ctaButton

        this.footerMenuGroup = response.footerMenuGroup

        this.socialLinks = response.Social.socialLinks

        this.subMenuGroup = response.subMenuGroup
      } catch (err) {
        return false
      }
    },
    trim(value) {
      return value.split(' ').join('')
    },
    breakpoints() {
      this.isMobile = window.innerWidth < 992
    },
    accordionFooter(index) {
      this.footerMenuIndex === index
        ? (this.footerMenuIndex = null)
        : (this.footerMenuIndex = index)
    },
    closeModalForm() {
      this.$refs.modalRef.closedModal();
      // this.$refs.modalRef2.closedModal();
      this.isModalOpen = false;

    },
    openModal() {
      this.isModalOpen = true;
      globalModalButton.click();

    },

    // addGlobalEventListeners() {
    //   const triggers = document.querySelectorAll('.contact-form-trigger');
    //   triggers.forEach(trigger => {
    //     trigger.addEventListener('click', () => {
    //       EventBus.$emit('open-modal');
    //     });
    //   });
    // },

    removeGlobalEventListeners() {
      const triggers = document.querySelectorAll('.contact-form-trigger');
      triggers.forEach(trigger => {
        trigger.removeEventListener('click', () => {
          EventBus.$emit('open-modal');
        });
      });
    },
 
    handleKeyPress(event) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        this.closeModalForm();
      }
    },

  },
}
